<template>
  <div :class="baseClass + '-info-page'">
    <div :class="baseClass + '-row'">
      <div :class="
          baseClass +
          '-info-container' +
          ' ' +
          baseClass +
          '-linear-border-container'
        ">
        <div :class="
            baseClass +
            '-info-content' +
            ' ' +
            baseClass +
            '-linear-border-content'
          ">
          <div :class="baseClass + '-info-title'">
            {{ info.name }}
          </div>
          <div :class="baseClass + '-info-container-content'">
            <img :src="'/static' + info.img" :class="baseClass + '-info-container-content-banner'" />
            <div :class="baseClass + '-info-container-content-box'">
              <div :class="baseClass + '-info-container-content-box-label'">
                Game introduction:
              </div>
              <div :class="baseClass + '-info-container-content-box-text'">
                {{ info.description }}
              </div>
              <div :class="baseClass + '-info-container-content-box-btn'">
                <div class="btn" @click="$router.push('/play/'+$route.params.id)">
                  <div class="text">PLAY NOW</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="baseClass + '-ad-box'">
      <div :class="baseClass + '-ad-box-title'">Advertisement</div>
      <div :class="baseClass + '-ad-box-content'"></div>
    </div>
    <div :class="baseClass + '-row'">
      <div :class="
          baseClass +
          '-all-container' +
          ' ' +
          baseClass +
          '-linear-border-container'
        ">
        <div :class="
            baseClass +
            '-all-content' +
            ' ' +
            baseClass +
            '-linear-border-content'
          ">
          <div :class="baseClass + '-container-title'">
            <div class="container">Games Topice</div>
          </div>
          <div :class="baseClass + '-all-container-list'">
            <Game v-for="item of games" :item="item" :key="item.id" type="new" />
          </div>
        </div>
      </div>
    </div>
    <div :class="baseClass + '-row'">
      <Tips />
    </div>
  </div>
</template>

<script>
import Game from "@/components/GameItem.vue";
import Tips from "@/components/Tips.vue";
export default {
  components: { Game, Tips },
  data () {
    return {
      games: [],
      info: {},
    };
  },
  watch: {
    $route: {
      handler () {
        this.init();
      },
      deep: true,
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      const id = this.$route.params.id;
      if (id) {
        this.$store.dispatch("getGameInfo", {
          id,
          callback: (game) => {
            this.info = game;
          },
        });
      }
      this.$store.dispatch("randomData", {
        num: this.isPid? 10 : 12,
        callback: (games) => {
          this.games = games;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-info-page {
  .#{$baseClass}-row {
    &:nth-child(1) {
      padding-top: 76px;
    }
  }
  .#{$baseClass}-container {
    &-title {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: -44px;
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        height: 88px;
        background: linear-gradient(180deg, #fed537 0%, #ff6b94 100%);
        box-shadow: 0px 4px 16px 0px rgba(255, 114, 142, 0.3);
        border-radius: 8px;
        font-size: 36px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #ffffff;
        line-height: 42px;
        text-shadow: 0px 4px 16px rgba(255, 114, 142, 0.3), 0px 4px 0px #db4d01;
      }
    }
  }
  .#{$baseClass}-info-container {
    width: 1360px;
    height: 480px;
    .#{$baseClass}-info-content {
      position: relative;
      background: #2b89ff;
      display: flex;
      flex-direction: column;
    }
    &-content {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 80px;
      &-banner {
        width: 316px;
        min-width: 316px;
        height: 316px;
        border-radius: 40px;
        border: 4px solid #fedc32;
        margin-right: 42px;
      }
      &-box {
        &-label {
          font-size: 32px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #ffffff;
          margin-bottom: 20px;
        }
        &-text {
          font-size: 20px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #fff;
          margin-bottom: 9px;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        &-btn {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          .btn {
            width: 270px;
            height: 94px;
            border-radius: 53px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            animation: shake-bottom 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955)
              infinite both;

            .text {
              position: relative;
              z-index: 3;
              font-size: 32px;
              font-family: Arial-BoldMT, Arial;
              font-weight: normal;
              color: #ffffff;
              line-height: 37px;
            }
            &::before,
            &::after {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              content: '';
              border-radius: 53px;
            }
            &::before {
              z-index: 1;
              background: #ee4a78;
              top: 4px;
            }
            &::after {
              z-index: 2;
              background: #fe5e8a;
            }
          }
        }
      }
    }
    .#{$baseClass}-info-title {
      height: 80px;
      background: #fedc32;
      border-radius: 18px 18px 0px 0px;
      font-size: 36px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #333333;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .#{$baseClass}-info-page {
    .#{$baseClass}-row {
      &:nth-child(1) {
        padding-top: 44px;
      }
    }
    .#{$baseClass}-info-container {
      width: 765px;
      height: 274px;
      &-content {
        flex: 1;
        padding-left: 12px;
        &-banner {
          width: 180px;
          height: 180px;
          min-width: 180px;
          border-radius: 10px;
          border: 2px solid #fedc32;
          margin-right: 21px;
        }
        &-box {
          &-label {
            margin-bottom: 11px;
            font-size: 18px;
          }
          &-text {
            font-size: 11px;
          }
          &-btn {
            .btn {
              width: 148px;
              height: 42px;
              .text {
                font-size: 18px;
                line-height: 23px;
              }
            }
          }
        }
      }
      .#{$baseClass}-info-title {
        height: 48px;
        border-radius: 10px 10px 0px 0px;
        font-size: 24px;
      }
    }

    .#{$baseClass}-container {
      &-title {
        top: -24px;
        .container {
          padding: 0 19px;
          height: 48px;
          font-size: 24px;
          line-height: 48px;
        }
      }
    }
    .#{$baseClass}-all-container-list {
      padding-top: 40px;
    }
  }
}

@media only screen and (min-width: 420px) and (max-width: 1300px) {
  .#{$baseClass}-info-page {
    .#{$baseClass}-game-item.new {
      margin-right: 10px;
    }
  }
}
</style>
