var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.baseClass + '-info-page'},[_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass +
        '-info-container' +
        ' ' +
        _vm.baseClass +
        '-linear-border-container'},[_c('div',{class:_vm.baseClass +
          '-info-content' +
          ' ' +
          _vm.baseClass +
          '-linear-border-content'},[_c('div',{class:_vm.baseClass + '-info-title'},[_vm._v(" "+_vm._s(_vm.info.name)+" ")]),_c('div',{class:_vm.baseClass + '-info-container-content'},[_c('img',{class:_vm.baseClass + '-info-container-content-banner',attrs:{"src":'/static' + _vm.info.img}}),_c('div',{class:_vm.baseClass + '-info-container-content-box'},[_c('div',{class:_vm.baseClass + '-info-container-content-box-label'},[_vm._v(" Game introduction: ")]),_c('div',{class:_vm.baseClass + '-info-container-content-box-text'},[_vm._v(" "+_vm._s(_vm.info.description)+" ")]),_c('div',{class:_vm.baseClass + '-info-container-content-box-btn'},[_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push('/play/'+_vm.$route.params.id)}}},[_c('div',{staticClass:"text"},[_vm._v("PLAY NOW")])])])])])])])]),_c('div',{class:_vm.baseClass + '-ad-box'},[_c('div',{class:_vm.baseClass + '-ad-box-title'},[_vm._v("Advertisement")]),_c('div',{class:_vm.baseClass + '-ad-box-content'})]),_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass +
        '-all-container' +
        ' ' +
        _vm.baseClass +
        '-linear-border-container'},[_c('div',{class:_vm.baseClass +
          '-all-content' +
          ' ' +
          _vm.baseClass +
          '-linear-border-content'},[_c('div',{class:_vm.baseClass + '-container-title'},[_c('div',{staticClass:"container"},[_vm._v("Games Topice")])]),_c('div',{class:_vm.baseClass + '-all-container-list'},_vm._l((_vm.games),function(item){return _c('Game',{key:item.id,attrs:{"item":item,"type":"new"}})}),1)])])]),_c('div',{class:_vm.baseClass + '-row'},[_c('Tips')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }